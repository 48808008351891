<template>
  <div class="project-feature-player" v-view.once="viewHandler" :class="{ 'project-feature-player--is-playing': isPlaying }" @mouseleave="onMouseLeave">
    <div class="project-feature-player__wrapper">
      <div class="project-feature-player__intro project-feature-player__intro--chapter" ref="intro_chapter" @click="skipIntro" v-if="teasedHeadline">
        <h3>{{ teasedHeadline }}</h3>
      </div>

      <div class="project-feature-player__intro" @click="skipIntro" ref="intro" v-if="!introDone">
        <h2 v-if="!introDone">{{ intro.headline }}</h2>
        <h3 v-if="!introDone">{{ intro.subline }}</h3>

        <div class="project-feature-player__intro-mobile-icon" v-if="mobileHintVisible" ref="mobile_hint">
          <inline-svg ref="mobile_hint_icon" :src="require('@/assets/svg/mobile-hint.svg')" />
        </div>
      </div>

      <div class="project-feature-player__controls">
        <div class="project-feature-player__progress-container">

          <div class="project-feature-player__progress">
            <div class="project-feature-player__progress-item" ref="progress_items" @mouseenter="showTooltip(index)"
              @mouseleave="hideTooltip(index)" @click="onProgressClick" v-for="(item, index) in videoDurations"
              :key="'pi_' + index" :style="{ 'width': ((item / featuresDuration) * 100) + '%' }">
              <div class="project-feature-player__progress-item-bg" />
              <div class="project-feature-player__progress-item-fill" />
              <Tooltip ref="tooltips" :label="content[index].caption" />

            </div>
          </div>
        </div>
        <div class="project-feature-player__chapter-toggle" @click="toggleChapters" :class="{'active':chaptersToggled}">
          <inline-svg :src="require('@/assets/svg/chapters.svg')" />

        </div>
      </div>

      <div class="project-feature-player__videos" @click="toggleVideoState" :class="{'visible':introDone}">
        <video :src="item.video" v-for="(item, index) in content" :key="'video_' + index" @timeupdate="onVideoProgress"
          @loadedmetadata="onVideoMeta" ref="videos" :class="{ 'active': activeVideo === index }" muted playsinline>
        </video>

        <div class="project-feature-player__videos-pause" ref="playpause">
        <inline-svg ref="playpause_icon" :src="require('@/assets/svg/pause.svg')" />
        </div>
      </div>

      <div class="project-feature-player__chapters" v-if="chaptersVisible" @mousemove="onChapterMouseMove"
        ref="chapters" :class="{'disabled':!chaptersToggled}">
        <div class="project-feature-player__chapters-wrapper" ref="chapter_wrapper">
          <div class="project-feature-player__chapter-item" @click="onChapterClick(index)"
            v-for="(item, index) in content" :key="'chapter_' + index" :class="{ 'active': activeVideo === index }">
            <img :src="item.image">
            <div class="project-feature-player__chapter-item-label">
              {{ item.caption }}
            </div>
          </div>

          <div class="project-feature-player__chapter-item-buffer"></div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
import Tooltip from '../Tooltip.vue'

export default {
  name: 'ProjectFeaturePlayer',
  props: {
    content: {},
    intro: {}
  },
  data () {
    return {
      isPlaying: true,
      introDone: false,
      videoDurations: [],
      videoPositions: [0, 0, 0, 0, 0, 0, 0],
      videoMeta: [],
      activeVideo: undefined,
      nextChapter: undefined,
      teasedHeadline: undefined,
      chaptersVisible: true,
      chaptersToggled: false,
      nextVideoPending: false
    }
  },
  computed: {
    mobileHintVisible () {
      return !this.introDone && window.innerWidth < window.innerHeight
    },
    featuresDuration () {
      return this.videoDurations.reduce((accumulator, value) => {
        return accumulator + value
      }, 0)
    }
  },
  watch: {
    '$screen.width' () {
      if (window.innerWidth > window.innerHeight && this.mobileHintVisible && !this.introDone) {
        clearTimeout(this.autoPlayTimer)
        this.autoPlayTimer = undefined

        this.autoPlayTimer = setTimeout(() => {
          this.skipIntro()
        }, 1000)
      }
    },
    mobileHintVisible () {
      if (this.mobileHintVisible) {

      }
    },
    chaptersToggled () {
      this.chaptersVisible = true
      this.$nextTick(() => {
        if (this.chaptersToggled) {
          gsap.to(this.$refs.chapter_wrapper, { y: 0, ease: 'Expo.easeOut', duration: 0.7, overwrite: true })
        } else {
          gsap.to(this.$refs.chapter_wrapper, { y: '100%', ease: 'Expo.easeOut', duration: 0.5 }).then(() => {
            this.chaptersVisible = false

            if (this.nextVideoPending) {
              this.onVideoComplete()
            }
          })
        }
      })
    },
    nextChapter () {
      this.nextVideoPending = false

      const nextChapterVideo = this.$refs.videos[this.nextChapter]
      if (nextChapterVideo) {
        const video = this.$refs.videos[this.activeVideo]
        if (video) { video.pause() }
        this.teaseChapter(this.content[this.nextChapter])
        this.chaptersToggled = false
      } else {
        this.chaptersToggled = true
      }
    },
    activeVideo () {
      const video = this.$refs.videos[this.activeVideo]
      video.play()
      video.currentTime = 0

      this.isPlaying = true
    },
    isPlaying () {
      this.$nextTick(() => {
        if (this.isPlaying) {
          gsap.to(this.$refs.playpause_icon.$el, { autoAlpha: 0, scale: 1.2 })
          gsap.to(this.$refs.playpause, { autoAlpha: 0 })
        } else {
          gsap.to(this.$refs.playpause_icon.$el, { duration: 2, scale: 1, ease: 'Expo.easeOut' })
          gsap.to(this.$refs.playpause_icon.$el, { autoAlpha: 1, duration: 0.25 })

          gsap.to(this.$refs.playpause, { autoAlpha: 1, duration: 0.25 })
        }
      })
    }
  },
  methods: {
    toggleVideoState () {
      this.isPlaying = !this.isPlaying

      if (this.isPlaying) {
        this.$refs.videos[this.activeVideo].play()
      } else {
        this.$refs.videos[this.activeVideo].pause()
      }
    },
    onMouseLeave () {
      this.chaptersToggled = false
    },
    isTouchDevice () {
      return (('ontouchstart' in window) ||
     (navigator.maxTouchPoints > 0) ||
     (navigator.msMaxTouchPoints > 0))
    },
    showMobileHint () {
      if (!this.$refs.mobile_hint_icon) return

      gsap.set(this.$refs.mobile_hint, { autoAlpha: 0 })
      gsap.to(this.$refs.mobile_hint, { duration: 0.5, autoAlpha: 1, delay: 0.5 })

      const phone1 = this.$refs.mobile_hint_icon.$el.getElementsByClassName('mobile-front')
      gsap.to(phone1, { transformOrigin: '100% 100%', y: '-5%', x: '-115%', rotation: 90, duration: 1, delay: 2, ease: 'Power4.easeInOut' })

      const phone2 = this.$refs.mobile_hint_icon.$el.getElementsByClassName('mobile')
      gsap.to(phone2, { opacity: 0, duration: 0.5, delay: 2 })

      const arrow = this.$refs.mobile_hint_icon.$el.getElementsByClassName('arrow')
      gsap.to(arrow, { transformOrigin: '50% 50%', opacity: 0, duration: 0.5, delay: 2, scale: 0 })
    },
    showTooltip (index) {
      if (this.isTouchDevice()) return

      const el = this.$refs.tooltips[index].$el

      gsap.to(el, { y: '-100%', ease: 'Expo.easeOut', overwrite: true })
      gsap.to(el, { autoAlpha: 1, duration: 0.2 })
    },

    hideTooltip (index) {
      const el = this.$refs.tooltips[index].$el
      gsap.to(el, { y: '-140%', ease: 'Expo.easeOut', duration: 0.2, overwrite: true })
      gsap.to(el, { autoAlpha: 0, duration: 0.2 })
    },
    toggleChapters () {
      this.chaptersToggled = !this.chaptersToggled
    },
    onChapterClick (index) {
      this.nextChapter = index
    },
    onChapterMouseMove (e) {
      if (this.isTouchDevice()) return

      let percentX = -0.2 + (e.clientX - this.$el.getBoundingClientRect().x) / this.$el.getBoundingClientRect().width * 1.4
      percentX = Math.max(Math.min(1, percentX), 0)

      const stripWidth = this.$refs.chapter_wrapper.scrollWidth
      let targetX = percentX * (this.$refs.chapter_wrapper.getBoundingClientRect().width - stripWidth)

      if (stripWidth <= this.$el.getBoundingClientRect().width) targetX = 0
      if (!this.chaptersToggled) targetX = 0

      gsap.to(this.$refs.chapter_wrapper, { ease: 'Expo.easeOut', duration: 1, x: targetX })
    },
    startAutoplayTimer () {
      clearTimeout(this.autoPlayTimer)
      this.autoPlayTimer = undefined

      this.autoPlayTimer = setTimeout(() => {
        this.skipIntro()
      }, 3000)
    },
    teaseChapter (content) {
      this.teasedHeadline = content.caption

      this.$nextTick(() => {
        gsap.set(this.$refs.intro_chapter.getElementsByTagName('h3'), { autoAlpha: 0, y: 0, overwrite: true })

        gsap.to(this.$refs.intro_chapter, { delay: 0, autoAlpha: 1, duration: 0.5 })
        gsap.to(this.$refs.intro_chapter.getElementsByTagName('h3'), { autoAlpha: 1, y: 0, duration: 1, ease: 'easeOutExpo', delay: 0.5 }).then(this.startAutoplayTimer)
      })
    },
    onProgressClick (e) {
      const index = this.$refs.progress_items.indexOf(e.target)
      if (index !== this.activeVideo) {
        this.nextChapter = index
        return
      }
      if (index === this.activeVideo) {
        // seek
        const rect = e.target.getBoundingClientRect()
        // const position = e.targetX;
        const percent = (e.clientX - rect.x) / rect.width
        this.$refs.videos[this.activeVideo].currentTime = this.$refs.videos[this.activeVideo].duration * percent
      }
    },
    skipIntro () {
      clearTimeout(this.autoPlayTimer)

      this.autoPlayTimer = null

      if (this.introDone) {
        gsap.to(this.$refs.intro_chapter.getElementsByTagName('h3'), { delay: 0, autoAlpha: 0, duration: 0.5 })
        gsap.to(this.$refs.intro_chapter, { delay: 0.4, autoAlpha: 0, duration: 1 })
        this.startNextVideo(this.nextChapter)
        return
      }

      if (this.$refs.mobile_hint) gsap.to(this.$refs.mobile_hint, { duration: 0.25, autoAlpha: 0 })
      gsap.to(this.$refs.intro.getElementsByTagName('h2'), { autoAlpha: 0, delay: 0, duration: 1 })
      gsap.to(this.$refs.intro.getElementsByTagName('h3'), { autoAlpha: 0, delay: 0, duration: 1 })
      gsap.to(this.$refs.intro.getElementsByTagName('h2'), { y: 0, duration: 2, ease: 'Expo.easeOut' })
      gsap.to(this.$refs.intro.getElementsByTagName('h3'), { y: 0, duration: 2, ease: 'Expo.easeOut' })

      gsap.to(this.$refs.intro, { autoAlpha: 0, delay: 0, duration: 1 }).then(() => {
        this.introDone = true
      })

      this.nextChapter = 0
    },
    startNextVideo (next) {
      // this.nextVideoPending = false

      if (next !== undefined) {
        this.activeVideo = next
        return
      }
      if (this.activeVideo === undefined) {
        this.activeVideo = 0
        return
      }
      this.activeVideo++
    },
    onVideoProgress (evt) {
      const currentProgress = evt.target.currentTime / evt.target.duration
      for (let i = 0; i < this.activeVideo; i++) {
        this.videoPositions[i] = 1
      }
      this.videoPositions[this.activeVideo] = currentProgress
      for (let i = this.activeVideo + 1; i < this.videoDurations.length; i++) {
        this.videoPositions[i] = 0
      }
      if (currentProgress >= 1) {
        if (!this.chaptersToggled) {
          this.onVideoComplete()
        } else {
          this.nextVideoPending = true
        }
      }
      this.updateProgressBars()
    },
    updateProgressBars () {
      const items = [...this.$el.getElementsByClassName('project-feature-player__progress-item')]
      items.forEach((item, index) => {
        gsap.set(item.getElementsByClassName('project-feature-player__progress-item-fill'), { width: (this.videoPositions[index] * 100) + '%' })
      })
    },
    onVideoMeta (evt) {
      this.videoDurations.push(evt.target.duration)
    },
    onVideoComplete (e) {
      this.nextChapter = this.activeVideo + 1
      // this.$refs.video.currentTime = 0
      // this.$refs.video.pause()
    },
    removeVideoListeners () {
      // const video = this.$refs.video
      // if (!video) return
      // this.$refs.video.removeEventListener('ended', this.onVideoComplete)
    },
    addVideoListeners () {
      // this.$refs.video.addEventListener('ended', this.onVideoComplete, false)
    },
    pauseVideo () {
      if (!this.isPlaying) { return }
      this.isPlaying = false
      if (window.innerWidth < 500) { return }
      this.$refs.video.pause()
      this.removeVideoListeners()
    },
    playVideo () {
      this.isPlaying = true
      if (window.innerWidth < 500) { return }
      this.$refs.video.play()
      this.addVideoListeners()
    },
    handleVideo (e) {
      /* if (e.type === 'progress' && e.percentInView >= 0.5) {
              if (!this.isPlaying) {
                gsap.to(this.$refs.image, { alpha: 0 })
                this.playVideo()
              }
            } else if (e.type === 'progress' && e.percentInView < 0.8) {
              if (this.isPlaying) {
                gsap.to(this.$refs.image, { alpha: 1 })
                this.pauseVideo()
              }
            } */

    },
    viewHandler (e) {
      if (this.mobileHintVisible) {
        // ANIMATE MOBILE HINT
        this.showMobileHint()
      } else {
        clearTimeout(this.autoPlayTimer)
        this.autoPlayTimer = undefined

        this.autoPlayTimer = setTimeout(() => {
          this.skipIntro()
        }, 2000)
      }
    }
  },
  beforeDestroy () {
    if (this.$refs.intro_chapter) gsap.killTweensOf(this.$refs.intro_chapter.getElementsByTagName('h3'))
    gsap.killTweensOf(this.$refs.chapter_wrapper)
    gsap.killTweensOf(this.$refs.intro)

    clearTimeout(this.autoPlayTimer)
    this.autoPlayTimer = undefined
  },
  components: { Tooltip }
}
</script>
