<template>
  <div ref="digit" class="project-overview-header__calendar-digitcontainer">
        <span class="project-overview-header__calendar-digit project-overview-header__calendar-digit--part0">0</span>
        <span class="project-overview-header__calendar-digit project-overview-header__calendar-digit--part1">0</span>
        <span class="project-overview-header__calendar-digit project-overview-header__calendar-digit--part2">0</span>
        <span class="project-overview-header__calendar-digit project-overview-header__calendar-digit--part3">0</span>
        <span class="project-overview-header__calendar-digit-shadow"></span>
      </div>

</template>

<script>
/* eslint-disable */

import { TweenMax, TimelineMax } from "gsap";

export default {
  name: "FlipDigitComponent",
  props: { targetDigit: 0 },
  data: function() {
    return { timeline: TimelineMax, progress: 0, state: 0 };
  },
  methods: {
    createTimeline() {
      let field = this.$refs.digit;

      const top = field.getElementsByClassName("project-overview-header__calendar-digit")[3];
      const middle = field.getElementsByClassName("project-overview-header__calendar-digit")[2];
      const bottom2 = field.getElementsByClassName("project-overview-header__calendar-digit")[1];
      const bottom1 = field.getElementsByClassName("project-overview-header__calendar-digit")[0];

      this.timeline = new TimelineMax({ paused: true });

      this.timeline.add(
        TweenMax.fromTo(
          top,
          1,
          {
            ease: "Linear.easeNone",
            rotationX: 0,
            force3D: true,
            delay: 0,
            backgroundColor: "#ffffff"
          },
          {
            ease: "Linear.easeNone",
            rotationX: 90,
            force3D: true,
            backgroundColor: "#dedede"
          }
        )
      );

      this.timeline.add(
        TweenMax.fromTo(
          middle,
          1,
          {
            rotationX: -90,
            alpha: 1,
            force3D: true,
            backgroundColor: "#dedede",
            ease: "Linear.easeNone"
          },
          {
            ease: "Linear.easeNone",
            rotationX: 0,
            force3D: true,
            backgroundColor: "#ffffff"
          }
        )
      );

      this.swapDigit(true);
    },
    swapDigit(initial) {
      let field = this.$refs.digit;
      const top = field.getElementsByClassName("project-overview-header__calendar-digit")[3];
      const middle = field.getElementsByClassName("project-overview-header__calendar-digit")[2];
      const bottom2 = field.getElementsByClassName("project-overview-header__calendar-digit")[1];
      const bottom1 = field.getElementsByClassName("project-overview-header__calendar-digit")[0];

      let current = Math.floor(this.progress);

      if (initial) {
        top.innerHTML = current;
        bottom1.innerHTML = current + 1;
        bottom2.innerHTML = current;
        middle.innerHTML = current;
      } else if (this.state === 0) {
        top.innerHTML = current + 1;
        middle.innerHTML = current + 1;
        bottom1.innerHTML = current + 1;
        bottom2.innerHTML = current;
      } else {
        top.innerHTML = current;
        bottom1.innerHTML = current + 1;
        bottom2.innerHTML = current;
        middle.innerHTML = current;
      }
    }
  },
  watch: {
    progress: function() {
      let cleaned = this.progress % 1;

      if (cleaned >= 0.5 && this.state === 0) {
        this.swapDigit();
        this.state = 1;
      } else if (cleaned < 0.5 && this.state === 1) {
        this.swapDigit();
        this.state = 0;
      }
      this.timeline.progress(cleaned);
    },
    targetDigit: function(change, before) {
      let speed = Math.abs(before - parseInt(this.targetDigit)) * 0.25;
      speed = 0.7 + Math.random() * 0.2;
      TweenMax.to(this, speed, {
        progress: this.targetDigit,
        ease: "Quad.easeInOut"
      });
    }
  },
  computed: {},

  created() {
    this.$nextTick(this.createTimeline);
  }
};
</script>