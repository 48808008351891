<template>
  <div class="projects">
    <Card :digit="currentDigit" ref="card">
      <transition name="fadeslide" mode="out-in" appear>
        <ProjectOverview v-if="!project" :content="contentJson"></ProjectOverview>
        <ProjectDetail :key="project" v-if="project" :project="project"></ProjectDetail>
      </transition>
    </Card>

  </div>
</template>

<script>
import Card from '@/components/Card'
import ProjectOverview from '@/components/projects/ProjectOverview'
import ProjectDetail from '@/components/projects/ProjectDetail'
import AppearMixin from '../mixins/AppearMixin'
import DataMixin from '../mixins/DataMixin'

export default {
  name: 'Projects',
  metaInfo: {
    title: 'MARCSTORCH.COM | PROJECTS'
  },
  mixins: [DataMixin, AppearMixin],
  components: {
    ProjectOverview, ProjectDetail, Card
  },
  data () {
    return {
      activeProject: null
    }
  },
  props: {
    project: undefined
  },
  computed: {
    currentDigit () {
      return '03'
    }
  },
  watch: {
    project () {
      if (!this.project) {
        document.title = 'MARCSTORCH.COM | PROJECTS'
      } else {
        document.title = 'MARCSTORCH.COM | PROJECTS | ' + this.project.toUpperCase()
      }
    }
  },
  mounted () {
    this.fetchData('projects')
  }
}
</script>
