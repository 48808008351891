<template>
  <div class="project-overview" :class="{ 'project-overview--scrolled': scrolled }">
    <div class="project-overview__content card__inner" v-if="content">
      <TickerHeadline>{{ content.headline }}</TickerHeadline>

      <div class="card__row card__row--full card__row--copy">
        <p class="copy">
          Some of them never saw the light of day, others I'm not allowed to show.<br>
          Nevertheless, here are some projects in which I have participated.
        </p>
      </div>

      <div class="project-overview__timeline-container" ref="timeline">

        <div class="project-overview__timeline-grid">

          <div class="project-overview__timeline">
            <div class="project-overview__shadow"></div>
            <div class="project-overview__timeline-date">
              <FlipDigitComponent :targetDigit="targetDigits[0]" />
              <FlipDigitComponent :targetDigit="targetDigits[1]" />
              <FlipDigitComponent :targetDigit="targetDigits[2]" />
              <FlipDigitComponent :targetDigit="targetDigits[3]" />
            </div>
          </div>
        </div>
      </div>

      <ProjectsTabs :content="content" @switch="onTabsSwitch" @scrolled="onScrolled" />

      <ContentEnd />
    </div>
  </div>
</template>

<script>
import TickerHeadline from '@/components/TickerHeadline'
// import Button from '../Button.vue'
import ProjectsTabs from './ProjectsTabs.vue'
import FlipDigitComponent from '../FlipDigitComponent.vue'
import ContentEnd from '@/components/ContentEnd.vue'
import gsap from 'gsap'

export default {
  components: { ContentEnd, TickerHeadline, ProjectsTabs, FlipDigitComponent },
  mixins: [],
  props: {
    content: undefined
  },
  data: function () {
    return {
      targetDigits: [0, 1, 2, 3, 4, 5, 6],
      items: [1, 2, 3],
      scrolled: false,
      mountedTime: undefined
    }
  },
  methods: {
    onScrolled (scrolled) {
      const mDuration = new Date().getTime() - this.mountedTime
      if (mDuration < 1000) return
      this.scrolled = scrolled
    },
    onTabsSwitch (item) {
      this.targetDigits = String(item.year).split('')
    },
    getCategories (item) {
      return item.categories.split(',')
    },
    onItemClick (slug) {
      this.$router.push('/projects/' + slug)
    }
  },
  watch: {
    scrolled () {
      if (this.scrolled) {
        gsap.set(this.$refs.timeline, { css: { display: 'block' } })
        gsap.to(this.$refs.timeline, {
          overwrite: true,
          ease: 'Expo.easeOut',
          xPercent: -50,
          y: '0'
        })
      }
      if (!this.scrolled) {
        gsap.to(this.$refs.timeline, {
          overwrite: true,
          duration: 0.7,
          ease: 'Expo.easeOut',
          xPercent: -50,
          y: '-105%'
        })
      }
    },
    content () {
      this.$nextTick(() => {
        this.targetDigits = String(this.content.projects[0].year).split('')
      })
    }
  },
  mounted () {
    this.mountedTime = new Date().getTime()
    this.$nextTick(() => {
      if (this.content) {
        this.targetDigits = String(this.content.projects[0].year).split('')
      }
    })
  }
}
</script>

<style>
</style>
