<template>
  <div class="project-overview-tabs__item-wrapper" v-view="viewHandler">
    <h2 class="project-overview-tabs__year cursor-hidden" v-if="item.showYear" v-html="item.year">

    </h2>

    <div
      class="project-overview-tabs__item"
      :class="{
        'project-overview-tabs__item--active': isActive,
        disabled: !item.slug,
        'project-overview-tabs__item--text-only':!item.preview,
      }"
      ref="item"
      :style="{ height: currentHeight }"
      @mouseenter="onMouseOver"
      @mouseleave="onMouseOut"
      @click="onClick"
    >
      <div class="project-overview-tabs__item-info" ref="info">
      <div class="project-overview-tabs__item-info-container" ref="info_container">

        <h3 ref="headline">{{ item.name }}</h3>
        <p class="info" v-if="item.client"><strong>CLIENT:</strong> {{ item.client }}</p>
        <p class="info" v-if="item.role"><strong>ROLE:</strong>  {{ item.role }}</p>
        <p class="info" v-if="item.description && !item.preview">{{ item.description }}</p>
        <ul>
          <li v-for="(item, index) in item.categories.split(',')" :key="index">
            {{ item }}
          </li>
        </ul>

      </div>
      </div>

      <div class="project-overview-tabs__item-image" v-if="item.preview" ref="image_container">
        <div class="project-overview-tabs__item-shadow"></div>
        <img
          :src="item.preview.image"
          alt=""
          ref="image"
          @load="onImageLoaded"
        />
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
export default {
  name: 'ProjectsTabsItem',
  components: {},
  props: {
    item: {},
    index: undefined
  },
  data () {
    return {
      mode: undefined,
      isActive: undefined,
      hovered: undefined,
      maxHeight: 0,
      minHeight: 0,
      animationProgress: 0
    }
  },
  computed: {
    currentHeight () {
      return (
        this.minHeight +
        (this.maxHeight - this.minHeight) * this.animationProgress +
        'px'
      )
    }
  },
  watch: {
    '$screen.width' () {
      this.setHoverSize()
    },
    isActive () {
      if (this.isActive) {
        if (this.mode === 'portrait') {
          this.onMouseOver()
        }

        gsap.to(this.$refs.item, { ease: 'Expo.easeOut', duration: 0.7, y: 0 })
      } else {
        if (this.mode === 'portrait') {
          this.onMouseOut()
        }
      }
    }
  },
  methods: {
    onImageLoaded () {
      if (this.index === 0) this.isActive = true

      if (this.$el.getBoundingClientRect().y <= window.innerHeight) this.isActive = true
      this.setHoverSize()
    },
    setHoverSize () {
      let mode = 'landscape'
      if (window.innerWidth < 1024) mode = 'portrait'

      this.mode = mode

      this.minHeight = this.$refs.info_container.getBoundingClientRect().height + 60

      if (this.minHeight < 100) this.minHeight = this.$refs.info.scrollHeight + 60

      this.maxHeight = this.minHeight
      if (this.$refs.image) {
        this.maxHeight = this.$refs.image.getBoundingClientRect().width * 0.536
        if (this.maxHeight < this.minHeight) this.maxHeight = this.minHeight

        if (mode === 'portrait') {
          this.minHeight += this.maxHeight
          this.maxHeight = this.minHeight * 1.2
        }
      }
    },
    onClick () {
      this.$emit('click')
    },
    onMouseOut (e) {
      // removes hover on portrait
      if (this.mode === 'portrait' && e) return

      this.hovered = false
      gsap.to(this, { overwrite: true, ease: 'Expo.easeOut', duration: 0.7, animationProgress: 0 })

      if (this.mode === 'portrait' && this.$refs.image) {
        // gsap.to(this.$refs.image_container, { xPercent: -50, y: '100%', overwrite: true, ease: 'Expo.easeOut', duration: 1 })
      }
    },
    onMouseOver () {
      this.hovered = true
      gsap.to(this, { overwrite: true, ease: 'Expo.easeOut', animationProgress: 1, duration: 1 })

      if (this.mode === 'portrait' && this.$refs.image) {
        // gsap.to(this.$refs.image_container, { xPercent: -50, y: 0, overwrite: true, ease: 'Expo.easeOut', duration: 1 })
      }
    },
    viewHandler (e) {
      if (e.type === 'progress' && this.item.showYear) {
        if (this.$el.getBoundingClientRect().y < window.innerHeight * 0.5) {
          this.$emit('activate', this.index)
        }
      }

      if (e.type === 'progress' && e.percentTop <= 0.95) {
        this.isActive = true
      } else if (e.type === 'exit') {
        // this.isActive = false
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.setHoverSize()
      setTimeout(() => { this.setHoverSize() }, 1000)
      gsap.to(this.$refs.item, { duration: 0, y: 40 })
    })
  },
  beforeDestroy () {}
}
</script>
