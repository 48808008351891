<template>
  <div class="tooltip">
    <div class="tooltip__label">
        {{label}}
    </div>

    <div class="tooltip__tip"></div>
  </div>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    label: undefined
  }
}
</script>
<style lang="scss" scoped>
.tooltip {
    pointer-events: none;
    position: relative;
    background-color: white;
    //font-size:14px;
    //font-weight: 400;
    text-transform: uppercase;
    padding:8px;
    padding-left:15px;
    padding-right: 15px;
    transform: translateY(-140%);
    opacity:0;
    visibility: hidden;
    bottom:8px;

    width: auto;
    flex-grow:0;
    display: inline-block;

    border-radius:5px;

    &__label {
        position: relative;
        z-index:1;
        font-size:14px;
        font-weight: 400;
        //white-space: nowrap;
    }

    &__tip {
        position: absolute;
        width:10px;
        height:10px;
        transform-origin: 50% 50%;
        background-color:white;
        bottom:-5px;
        left:17px;
        transform: rotate(-45deg);
    }

    filter: drop-shadow(0px 0px 2px rgba(0,0,0,.5));
}

</style>
