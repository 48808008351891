<template>
  <div class="project-overview-tabs">

    <ProjectsTabsItem
      :item="item"
      v-for="(item, index) in allProjects"
      :key="index"
      :index="index"
      @activate="setActiveIndex"
      @click="onItemClick(item.slug)"
    />
  </div>
</template>

<script>
import ProjectsTabsItem from './ProjectsTabsItem.vue'

export default {
  components: { ProjectsTabsItem },
  name: 'ProjectTabs',
  props: {
    content: {}
  },
  data () {
    return {
      activeIndex: 0,
      scrolled: false
    }
  },
  computed: {
    allProjects () {
      const projects = []
      this.content.projects.forEach(year => {
        year.items.forEach(project => {
          project.year = year.year

          const last = projects[projects.length - 1]
          if (last) {
            if (last.year !== project.year) project.showYear = true
          } else {
            project.showYear = true
          }
          if (!project.hidden) projects.push(project)
        })
      })
      return projects
    }
  },
  watch: {
    '$scroll.y' () {
      if (this.$el.getBoundingClientRect().y < 0) {
        this.scrolled = true
      } else {
        this.scrolled = false
      }
    },
    scrolled () {
      this.$emit('scrolled', this.scrolled)
    },
    activeIndex () {
      this.$emit('switch', this.allProjects[this.activeIndex])
    }
  },
  methods: {
    onItemClick (slug) {
      if (!slug) return
      this.$router.push('/projects/' + slug)
    },
    setActiveIndex (index) {
      this.activeIndex = index
    }
  },
  mounted () {

  },
  beforeDestroy () {}
}
</script>
