<template>
  <div class="project-detail" v-if="contentJson">
    <div class="project-detail__content card__inner">
      <router-link to="/projects">
        <div class="project-detail__back-btn">
          <inline-svg :src="require('@/assets/svg/close-new.svg')" />
        </div>
      </router-link>

      <div class="project-detail__header">
        <TickerHeadline>{{ contentJson.headline }}</TickerHeadline>

        <div class="card__row card__row--third">
          <h4>CLIENT</h4>
          <p>{{ contentJson.client }}</p>
        </div>

        <div class="card__row card__row--third">
          <h4>Frontend</h4>
          <p>{{ contentJson.frontend }}</p>
        </div>

        <div class="card__row card__row--third">
          <h4>backend</h4>
          <p>{{ contentJson.backend }}</p>
        </div>

        <div class="card__row card__row--full card__row--copy" v-if="contentJson.tasks">
          <p class="copy" v-html="contentJson.tasks">

          </p>
        </div>

        <div class="card__row card__row--full card__row--list">
          <h4>TASKS & RESPONSIBILITIES</h4>
          <ul>
            <li v-for="(item, index) in contentJson.function" :key="index">
              {{ item }}
            </li>
          </ul>
        </div>

        <div
          class="card__row card__row--full  card__row--list"
          v-if="contentJson.additional_tools"
        >
          <h4>ADDITIONAL TOOLS USED</h4>
          <ul>
            <li
              v-for="(item, index) in contentJson.additional_tools"
              :key="index"
            >
              {{ item }}
            </li>
          </ul>
        </div>
      </div>

      <div class="project-detail__features">
        <div class="project-detail__features-header">
          <TickerHeadline delay="0.5">FEATURES</TickerHeadline>
          <!--<Button>WATCH FEATURETTE</Button>-->
        </div>

        <div
          class="card__row card__row--full project-detail__features-player">
          <ProjectFeaturePlayer :intro="contentJson.intro" :content="contentJson.videos"></ProjectFeaturePlayer>
      </div>

      <ContentEnd :next="getNext()"/>
    </div>
  </div>
  </div>
</template>

<script>
import DataMixin from '@/mixins/DataMixin'
import ContentEnd from '@/components/ContentEnd'
import TickerHeadline from '@/components/TickerHeadline'
// import ProjectFeature from '@/components/projects/ProjectFeature'
import ProjectFeaturePlayer from './ProjectFeaturePlayer.vue'

export default {
  components: { TickerHeadline, ContentEnd, ProjectFeaturePlayer },
  mixins: [DataMixin],
  props: {
    project: null
  },
  watch: {
    project () {
      this.loadProject()
    }
  },
  methods: {
    getNext () {
      let next = { target: 'projects', slug: this.contentJson.next, label: 'NEXT CASE' }
      if (!this.contentJson.next) next = undefined
      return next
    },
    loadProject () {
      this.fetchData('projects/' + this.project)
    }
  },
  mounted () {
    // project
    if (this.project) {
      this.$nextTick(() => {
        this.loadProject()
      })
    }
  }
}
</script>

<style></style>
